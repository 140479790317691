// === when document ready === //
$(document).on("ready", function () {
    // from demo-7
    $('.owl-carousel.default').owlCarousel({
        loop: true,
        responsiveClass: true,
        // autoplay: true,
        // autoplayTimeout: 3000,
        // autoplayHoverPause: true,
        // smartSpeed: 1000,
        // nav: true,
        // dots: true,
        // center: false,
        margin: 30,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
        responsive: {
            0: {
                items: 1,
                nav: true,
                dots: false
            },
            568: {
                items: 2,
                nav: false
            },
            992: {
                items: 3,
                loop: false
            }
        }
    });
    $('.owl-carousel.logos').owlCarousel({
        loop: true,
        responsiveClass: true,
        autoplay: true,
        autoplayTimeout: 2000,
        smartSpeed: 1000,
        margin: 40,
        loop: true,
        navText: ["<i class='fas fa-arrow-left'></i>", "<i class='fas fa-arrow-right'></i>"],
        responsive: {
            0: {
                items: 1,
                nav: true,
                dots: false
            },
            568: {
                items: 3,
                nav: false
            },
            992: {
                items: 5,
            }
        }
    });
});

(function () {
    /* Megamenu */
    var links = document.querySelectorAll(".megamenu .links .link");
    if (links) {
        links.forEach(function (link) {
            link.addEventListener("mouseover", linkHover);
        });
    }

    function linkHover(event) {
        deactivateLinks();
        activateLink(event.currentTarget);
        showHideContent(event.currentTarget);
    }

    function deactivateLinks() {
        links.forEach(function (link) {
            link.classList.remove("active");
            link.classList.add("inactive");
        });
    }

    function activateLink(target) {
        target.classList.add("active");
        target.classList.remove("inactive");
    }

    function showHideContent(target) {
        id = target.dataset.linkNumber;
        var currentContent = document.querySelectorAll('.megamenu .page-content:not([data-content="' + id + '"])').forEach(function (item) {
            item.style.display = "none";
        });
        var newContent = document.querySelector('.megamenu .page-content[data-content="' + id + '"]');
        newContent.style.display = "flex";
    }

    function resetMegaMenu() {
        // console.log("Reset");
        // deactivateLinks();
        // var firstLink = document.querySelector('.links .link[data-link-number="0"]')
        // activateLink(firstLink);
    }

    var megamenu = document.querySelector(".megamenu");
    var menuBlock = document.querySelector(".megamenu > .menu-block");
    if (megamenu) {
        megamenu.addEventListener("mouseover", function () {
            menuBlock.style.display = "block";
        });
        megamenu.addEventListener("mouseout", function () {
            menuBlock.style.display = "none";
        });
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    // Select the node that will be observed for mutations
    var targetNode = document.querySelector('.menu-block');

    // Options for the observer (which mutations to observe)
    var config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    var callback = function (mutationsList, observer) {
        // Use traditional 'for loops' for IE 11
        for (let mutation of mutationsList) {
            if (mutation.attributeName === "style") {
                // console.log(mutation.target.style.display);
                if (mutation.target.style.display === "none") {
                    resetMegaMenu();
                }
            }
        }
    };

    // Create an observer instance linked to the callback function
    var observer = new MutationObserver(callback);

    if (targetNode) {
        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    }
})();